<template>
  <div>
    <RocketLoad
      v-if="isLoading"
      :full="true"
    />
    <div
      class="formgrid grid pb-3"
      v-else
    >
      <!-- Header Section -->
      <div class="field col-6">
        <h2>Recurrence detail</h2>
      </div>
      <div class="field col-6">
        <div class="p-inputgroup justify-content-end">
          <AInputSwitch
            v-model="active"
            class="green"
          />
        </div>
      </div>
      <Divider class="m-2 mb-5"></Divider>

      <RecurrenceForm></RecurrenceForm>
    </div>
  </div>
</template>
  
  <script>
import { state } from "../../../services/data_service";
import RecurrenceForm from "../../Job/components/RecurrenceForm.vue";

export default {
  components: { RecurrenceForm },
  name: "RecurrenceSlider",

  data() {
    return {
      submitted: false,
      active: true,
    };
  },

  computed: {
    isLoading() {
      return this.$store.getters.sliderLoader;
    },
  },
};
</script>

<style scoped>
</style>